import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const BackgroundImage = ({ image }) => {
  return (
    <div className="fixed inset-0 opacity-30">
      <GatsbyImage
        image={getImage(image.localFile.childImageSharp.gatsbyImageData)}
        alt={image.title}
        title={image.title}
        className="h-full lg:w-3/4 lg:m-auto"
        objectFit="contain"
      />
    </div>
  )
}

export default BackgroundImage
