import React from "react"
import { gsap } from "gsap"
import CarouserElements from "./CarouselElements"
import useTabIsUsed from "../../hooks/useTabIsUsed"
import getClassNamesByTabIsUsedState from "../../helpers/getClassNamesByTabIsUsedState"

const Carousel = ({ media: items }) => {
  const tabIsUsed = useTabIsUsed()
  const [currentItemIndex, setCurrentItemIndex] = React.useState(0)
  const itemsRefs = items?.map(() => React.createRef())
  React.useEffect(() => {
    if (!items) return
    gsap
      .timeline()
      .addLabel("start")
      .to(itemsRefs[currentItemIndex].current, {
        autoAlpha: 1,
      })
      .to(
        itemsRefs
          .filter((_, index) => index !== currentItemIndex)
          .map(item => item.current),
        {
          autoAlpha: 0,
        },
        "start"
      )
  }, [currentItemIndex, itemsRefs, items])

  if (!items) return ""
  const currentItem = items[currentItemIndex]
  const firstItemIndex = currentItemIndex === 0
  const lastItemIndex = currentItemIndex === items.length - 1
  const isOnlyOneItem = items.length === 1

  return (
    <div className="relative h-0 pt-16/9">
      {!isOnlyOneItem && (
        <button
          className={`z-20 h-12 w-10 absolute left-0 inset-y-1/2 transform -translate-y-1/2 p-2 text-primary hover:text-light ${getClassNamesByTabIsUsedState(
            tabIsUsed
          )}`}
          onClick={() => {
            setCurrentItemIndex(
              !firstItemIndex ? currentItemIndex - 1 : items.length - 1
            )
          }}
          title="back"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 26 76"
            fill="none"
            strokeWidth={10}
            className="h-full w-full stroke-current "
          >
            <path d="M25 0.999989L1.99997 38L25 75" />
          </svg>
        </button>
      )}
      <CarouserElements
        items={items}
        itemsRefs={itemsRefs}
        currentItemIndex={currentItemIndex}
        tabIsUsed={tabIsUsed}
      />
      {!isOnlyOneItem && (
        <button
          className={`z-20 h-12 w-10 absolute right-0 inset-y-1/2 transform -translate-y-1/2 p-2 text-primary hover:text-light ${getClassNamesByTabIsUsedState(
            tabIsUsed
          )}`}
          onClick={() => {
            setCurrentItemIndex(!lastItemIndex ? currentItemIndex + 1 : 0)
          }}
          title="next"
        >
          <svg
            viewBox="0 0 26 76"
            fill="none"
            strokeWidth={10}
            className="h-full w-full stroke-current"
          >
            <path d="M0.999999 75L24 38L0.999999 1" />
          </svg>
        </button>
      )}
      <div className="flex absolute bottom-3 w-full z-10 -m-2 items-center justify-center space-x-2">
        {!isOnlyOneItem &&
          items.map(({ id }, index) => {
            return (
              <button
                key={id}
                className={`h-2 ${getClassNamesByTabIsUsedState(tabIsUsed)}`}
                onClick={() => setCurrentItemIndex(index)}
                title={`slide ${index + 1}`}
                aria-label={`slide ${index + 1}`}
              >
                <div
                  className={`block w-6 z-20 ${
                    currentItemIndex === index
                      ? "bg-light h-0.5"
                      : "bg-primary h-px"
                  }
              `}
                />
              </button>
            )
          })}
        {currentItem.image && (
          <a
            className={`absolute right-3 bottom-0 w-8 h-8 lg:w-6 lg:h-6 text-primary hover:text-light ${getClassNamesByTabIsUsedState(
              tabIsUsed
            )}`}
            href={currentItem.image.localFile.publicURL}
            target="_blank"
            rel="noreferrer"
            aria-label="image-link"
            title="image-link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
              />
            </svg>
          </a>
        )}
      </div>
    </div>
  )
}

export default Carousel
