import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import useTabIsUsed from "../../hooks/useTabIsUsed"
import Carousel from "./Carousel"
import addLineBreaks from "../../helpers/addLineBreaks"
import getClassNamesByTabIsUsedState from "../../helpers/getClassNamesByTabIsUsedState"

const ProjectLayout = ({ title, body, projectExternalLink, media }) => {
  const tabIsUsed = useTabIsUsed()
  const richTextOptions = {
    renderText: addLineBreaks,
  }

  console.log(body, "richTextOptions")
  return (
    <div className="lg:flex justify-between mx-5 mt-16 relative font-light">
      <div className="lg:w-1/2 flex flex-col lg:items-start">
        <h2 className="text-primary text-3xl text-center lg:text-left">
          {title}
        </h2>
        {body !== null ? renderRichText(body, richTextOptions) : null}
        {projectExternalLink && (
          <a
            className={`mt-4 flex items-center text-primary space-x-3 mx-auto lg:mx-0 ${getClassNamesByTabIsUsedState(
              tabIsUsed
            )}`}
            href="https://www.google.com"
            target="_blank"
            rel="noreferrer"
          >
            <span>Link To Project:</span>
            <svg
              className="w-5 h-5 inline"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </a>
        )}
      </div>
      <div className="w-full mt-8 lg:mt-0 lg:w-2/5">
        <Carousel media={media} />
      </div>
    </div>
  )
}
export default ProjectLayout
