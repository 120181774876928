import React from "react"
import BackgroundImage from "./BackgroundImage"
import ProjectLayout from "./ProjectLayout"
import Video from "./Video"

import tw from "twin.macro"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import addLineBreaks from "../../helpers/addLineBreaks"

const RichTextWrapper = styled.div`
  p {
    ${tw`max-w-lg mt-4 text-xl mx-auto font-light`}
  }
`

const ProjectsPageLayout = ({ data, logo: Logo }) => {
  const backgroundImage = data.page.backgroundImage
  console.log(backgroundImage, "backgroundImage")
  const richTextOptions = {
    renderText: addLineBreaks,
  }
  return (
    <div className="relative">
      {backgroundImage !== null ? (
        <BackgroundImage image={backgroundImage} />
      ) : (
        <Video
          className="fixed inset-0 h-full min-w-full object-cover opacity-30"
          title={data.page.backgroundVideo.title}
          url={data.page.backgroundVideo.json.secure_url}
          poster={data.page.poster}
          loop
          autoPlay
          muted
        />
      )}

      <div tw="mx-auto pt-16 lg:(px-10 pt-24)">
        <div className="flex flex-col">
          <Logo className="w-80 mx-auto relative" />
          <div className="relative mt-10 mx-auto text-center lg:w-1/2">
            <h1 className="text-6xl text-primary">{data.page.product.title}</h1>
            <RichTextWrapper>
              {renderRichText(data.page.product.description, richTextOptions)}
            </RichTextWrapper>
            {/* <Link
              className={`text-white bg-primary px-4 py-2 inline-block mt-4 ${getClassNamesByTabIsUsedState(
                tabIsUsed
              )} focus:ring-white`}
              to={locale === "en-US" ? "/contact/" : "/de/contact/"}
            >
              Contact Us
            </Link> */}
          </div>
        </div>
        <div className="mt-48">
          {data.page.projects &&
            data.page.projects.map(
              ({ id, title, body, projectExternalLink, media }) => (
                <ProjectLayout
                  key={id}
                  title={title}
                  body={body}
                  media={media}
                  projectExternalLink={projectExternalLink}
                />
              )
            )}
        </div>
      </div>
    </div>
  )
}

export default ProjectsPageLayout
