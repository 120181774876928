import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Video from "./Video"
import getClassNamesByTabIsUsedState from "../../helpers/getClassNamesByTabIsUsedState"

const CarouserElements = ({
  items,
  itemsRefs,
  currentItemIndex,
  tabIsUsed,
}) => {
  return items.map((item, index) => {
    const type = item.video
      ? "video"
      : item.image
      ? "image"
      : item.url
      ? "embed"
      : ""

    let mediaElement
    switch (type) {
      case "image":
        {
          const { title, localFile } = item.image
          mediaElement = (
            <GatsbyImage
              className="h-full"
              objectFit="contain"
              image={getImage(localFile.childImageSharp.gatsbyImageData)}
              title={title}
              alt={title}
            />
          )
        }
        break
      case "video":
        {
          const { video, poster, title } = item
          console.log(poster?.localFile.publicUrl, "poster")

          mediaElement =
            currentItemIndex === index ? (
              <Video
                className={`h-full ${getClassNamesByTabIsUsedState(tabIsUsed)}`}
                url={video.secure_url}
                poster={poster?.localFile.publicUrl}
                controls
                title={title}
              />
            ) : (
              ""
            )
        }
        break
      case "embed":
        {
          const { url, title } = item
          mediaElement =
            currentItemIndex === index ? (
              <iframe
                className="h-full w-full"
                title={title}
                src={url + "?playsinline=1"}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
              ""
            )
        }
        break
      default:
        break
    }

    return (
      <div
        key={item.id}
        ref={itemsRefs[index]}
        className="invisible opacity-0 absolute inset-0 z-10 bg-black h-full"
      >
        {mediaElement}
      </div>
    )
  })
}

export default CarouserElements
