import React from "react"
import Layout from "../components/reusables/Layout"
import ProjectsPageLayout from "../components/reusables/ProjectsPageLayout"
import SEO from "../components/reusables/seo"
import { graphql } from "gatsby"
import getSvgLogoComponent from "../helpers/getSvgLogoComponent"

const ProjectsPageTemplate = ({ data }) => {
  const title = data.page.title
  const logoComponent = getSvgLogoComponent(data.page.logo)

  return (
    <>
      <SEO title={title} />
      <Layout>
        <section id="main">
          <ProjectsPageLayout data={data} logo={logoComponent} />
        </section>
      </Layout>
    </>
  )
}

export default ProjectsPageTemplate

export const query = graphql`
  query($slug: String!) {
    page: contentfulProjectsPage(slug: { eq: $slug }) {
      title
      product {
        title
        description {
          raw
        }
      }
      logo {
        title
      }
      backgroundImage {
        ...ContentfulImage
      }
      backgroundVideo {
        title
        json: childContentfulCloudinaryVideoVideoJsonNode {
          secure_url
        }
        poster {
          localFile {
            publicURL
          }
        }
      }
      id
      projects {
        id
        title
        projectExternalLink
        body {
          raw
        }
        media {
          ... on ContentfulProjectImage {
            id
            image {
              ...ContentfulImage
            }
          }
          ... on ContentfulCloudinaryVideo {
            id
            title
            video: childContentfulCloudinaryVideoVideoJsonNode {
              secure_url
            }
            poster {
              localFile {
                publicURL
              }
            }
          }
          ... on ContentfulEmbeddedVideo {
            id
            url
            title
          }
        }
      }
    }
  }
`
